import React from "react";

import { graphql } from "gatsby";

import { IndexPageTemplate } from "../templates/index-page";
import Layout from "../components/Layout";

import { Helmet } from "react-helmet";
import useSiteMetadata from "../components/SiteMetadata";

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const { title, description } = useSiteMetadata();

  return (
    <React.Fragment>
      <Helmet>
        <html lang="de" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta property="og:description" content={description} />
      </Helmet>
      <Layout>
        <IndexPageTemplate
          herosection={frontmatter.herosection}
          news={frontmatter.news}
          team={frontmatter.team}
          services={frontmatter.services}
          contact={frontmatter.contact}
        />
      </Layout>
    </React.Fragment>
  );
};

export default IndexPage;

export const query = graphql`
  {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        herosection {
          address
          email
          fax
          subtitle
          telephone
          title
          careerText
        }
        contact {
          addressTitle
          addressSubtitle
          approach {
            title
            attributes {
              description
              imageObject {
                alt
                image {
                  publicURL
                }
              }
            }
          }
          earlyHours {
            times
            title
          }
          googleMapsLink
          googleMapsEmbedLink
          openingHours {
            days {
              day
              times {
                time
              }
            }
            description
            title
          }
          title
        }
        news {
          content
          title
        }
        services {
          content
          title
        }
        team {
          linkTextTeam
          title
          doctors {
            name
            imageObject {
              alt
              image {
                childImageSharp {
                  gatsbyImageData (width: 240)
                }
              }
            }
            linkText
          }
        }
      }
    }
  }
`;
